import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import {Layout} from '../components/layout/layout'
import Seo from '../components/seo/seo'
import Hero from '../components/heroBanner/heroBanner'
import { SsyLink } from '../components/ssyLink/ssyLink'
import HtmlPrinter from '../components/htmlPrinter/htmlPrinter'
import { RaisingBox } from '../components/raisingBox/raisingBox'
import { SsyIntersectionObserver } from '../components/ssyIntersectionObserver/ssyIntersectionObserver'
import style from '../assets/css/infoPage.module.css';
const LegalPage = (props) => {
  const intl = useIntl()

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: 'LEGAL_META_TITLE' })}
        lang={intl.formatMessage({ id: 'MAIN_LANGUAGE' })}
        url={props.location.pathname}
        description={intl.formatMessage({ id: 'LEGAL_META_DESCRIPTION' })}
      />
      <Hero title={intl.formatMessage({ id: 'LEGAL_TITLE' })} />
      <section className={`${style.infoSection}`}>
        <SsyIntersectionObserver>
          <RaisingBox>
            <article className={style.marginM}>
              <h3>{intl.formatMessage({ id: 'LEGAL_COMPANY_DETAILS' })} </h3>
              <p className={style.marginXS}>
                {intl.formatMessage({ id: 'LEGAL_COMPANY_NAME' })}
              </p>
              <p className={style.marginXS}>
                {intl.formatMessage({ id: 'LEGAL_COMPANY_ADDRESS' })}
              </p>
              <p className={style.marginXS}>
                {intl.formatMessage({ id: 'LEGAL_COMPANY_LOCATION' })}
              </p>
              <p className={style.marginXS}>
                {intl.formatMessage({ id: 'LEGAL_COMPANY_COUNTRY' })}
              </p>
              <p className={style.marginXS}>
                {intl.formatMessage({ id: 'LEGAL_COMPANY_NUMBER' })}
              </p>
            </article>
          </RaisingBox>

          <RaisingBox>
            <article className={style.marginM}>
              <h3>
                {intl.formatMessage({ id: 'LEGAL_REPRESENTED_DETAILS' })}{' '}
              </h3>
              <p className={style.marginXS}>
                {intl.formatMessage({ id: 'LEGAL_REPRESENTED_NAMES' })}
              </p>
            </article>
          </RaisingBox>
          <RaisingBox>
            <article className={style.marginM}>
              <h3>{intl.formatMessage({ id: 'LEGAL_CONTACT_DETAILS' })} </h3>
              <p className={style.marginXS}>
                {intl.formatMessage({ id: 'LEGAL_CONTACT_PHONE' })}
              </p>
              <p className={style.marginXS}>
                {intl.formatMessage({ id: 'LEGAL_CONTACT_EMAIL' })}
              </p>
              <p className={style.marginS}>
                <HtmlPrinter
                  content={intl.formatMessage({ id: 'LEGAL_CONTACT_ODR' })}
                />
              </p>
            </article>
          </RaisingBox>
        </SsyIntersectionObserver>
        <SsyLink
              type="primary"
              to={`${intl.formatMessage({
                id: 'FOOTER_SUPPORT_CONTACT_LINK',
              })}`}
              internal={false}
              label={intl.formatMessage({ id: 'ABOUTUS_BUTTON' })}
            />
        <div className={style.marginM} />
      </section>
    </Layout>
  )
}

export default LegalPage
